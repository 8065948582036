import { useI18n, useSupabaseClient, useToast } from '#imports';

export const useCreators = () => {
  const { t } = useI18n();
  const { showError } = useToast();
  const supabase = useSupabaseClient();

  const hasApplied = async (creatorId: string) => {
    const { data, error } = await supabase
      .from('terms_audit_log')
      .select('*')
      .eq('user_id', creatorId);

    if (error) return false;
    return data?.length > 0;
  };

  const fetchFaqByCreatorId = async (creatorId: string) => {
    const { data, error } = await supabase
      .from('creator_faq')
      .select('*')
      .eq('user_id', creatorId);

    if (error) showError('Could not fetch info');
    return data;
  };

  const fetchCreatorByHandle = async (handle: string) => {
    const { data, error } = await supabase
      .from('profiles')
      .select(`
        *,
        maps (
          id,
          title,
          cover_image_url,
          current_slug,
          spots_count,
          is_published,
          spots:spots (
            id,
            name,
            summary,
            cover_image_url,
            categories:category_id (
              id,
              name,
              icon
            ),
            cities:cities_id (
              id,
              name,
              country
            )
          )
        )
      `)
      .eq('mappr_handle', handle)
      .single();
    if (error) showError('Could not fetch info');
    data.faqItems = await fetchFaqByCreatorId(data.id);
    if (data.maps) {
      data.spotsCount = data.maps
        .map((map) => map.spots_count)
        .reduce((sum, item) => sum + item, 0);
    }
    return data;
  };

  const fetchCreatorById = async (creatorId: string) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', creatorId)
      .single();
    if (error) showError('Could not fetch info');
    return data;
  };

  const fetchCreators = async (filters, search) => {
    let query = supabase
      .from('profiles')
      .select(`
        *,
        maps (
          id,
          title,
          cover_image_url,
          current_slug,
          spots_count
        )
      `)
      .eq('is_creator', true)
      .eq('is_published', true);
    for (const filter of filters) {
      query = query[filter.operator](filter.value, filter.arg);
    }
    if (search) {
      query = query.ilike('mappr_handle', `%${search}%`);
    }
    const { data, error } = await query;
    if (error) showError(t('messages.maps.error-loading'));
    return data;
  };

  const fetchRecentCreators = async (limit = 5) => {
    const { data, error } = await useSupabaseClient()
      .from('profiles')
      .select('*')
      .eq('is_published', true)
      .eq('is_creator', true)
      .limit(limit);
    if (error) showError(t('messages.creators.notFetchCreators'));
    return data;
  };

  const fetchFeaturedCreators = async (limit = 5) => {
    const { data, error } = await useSupabaseClient()
      .from('profiles')
      .select(`
        *,
        maps (
          id,
          title,
          cover_image_url,
          current_slug,
          spots_count
        )
      `)
      .eq('is_featured', true)
      .eq('is_creator', true)
      .order('updated_at', { ascending: false })
      .limit(limit);
    if (error) showError(t('messages.creators.notFetchCreators'));
    return data;
  };

  return {
    fetchCreatorByHandle,
    fetchRecentCreators,
    fetchFeaturedCreators,
    fetchCreatorById,
    fetchFaqByCreatorId,
    fetchCreators,
    hasApplied,
  };
};
